<template>
   <b-container>
      <Linear v-if="contract && contract.type === 'LinearLoanContract'" :contract="contract" @added="addSchemaMutation" @close="$emit('close')" />
      <Annuity v-if="contract && contract.type === 'AnnuityLoanContract'" :contract="contract" @added="addSchemaMutation" @close="$emit('close')" />
      <RepaymentFree v-if="contract && contract.type === 'RepaymentFreeLoanContract'" :contract="contract" @added="addSchemaMutation" @close="$emit('close')" />

      <b-table
        class="mt-1"
        responsive
        borderless
        sticky-header="150px"
        small
        sort-by="date"
        :sort-desc="true"
        :items="contract.contract.schema_mutations"
        :fields="mutationTable.fields"
         v-if="contract.contract.schema_mutations.length > 0"
      >

        <template #cell(action)="row">
          <b-button variant="danger" @click="deleteSchemaWijziging(row.index, row.item.hashid)">
            Verwijder
          </b-button>
        </template>
      </b-table>
   </b-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// import store from '@/store'
import Annuity from './Annuity'
import Linear from './Linear'
import RepaymentFree from './RepaymentFree'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    contract: {
      type: Object,
      default: null
    }
  },
  components: {
    Annuity, Linear, RepaymentFree, ToastificationContent
  },
  data() {
    return {
      deleting: new Map(),
      mutationTable: {
        fields: [
          { key: 'date', label: 'Datum',
            formatter: (value) => {
              return new Intl.DateTimeFormat('nl-NL', {year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(value));
            }
          },
          { key: 'type', label: 'Type schema wijziging',
            formatter: (value) => {
              return value === 1 ? "Periode wijziging" : "Bedrag wijziging"
            },
          },
          { key: 'value', label: 'Naar waarde',
            formatter: (value) => {
              return value;
            },
          },
          { key: 'action', label: 'Actie'},
        ],
      },
    }
  },
  methods: {
    deleteSchemaWijziging(index, _db) {
      let _this = this;
      
      if(this.deleting.get(_db)) return;

      let formData = new FormData();
      formData.append("hashid", _db);
      formData.append("_method", "DELETE");
      
      this.deleting.set(_db, _db);

      this.$http.post("/schemamutation",formData).then(() => {
          _this.contract.contract.schema_mutations.forEach((mut, mut_index) => {
            if(mut.hashid === _db) {
              _this.contract.contract.schema_mutations.splice(mut_index, 1);
            }
          });
          // console.log(_this.contract_selected.contract.bank_mutations[index].hashid);
          // let array = [];
          // _this.contract_selected.contract.bank_mutations.forEach((mut) => {
          //   if(mut.hashid !== _db) {
          //     array.push(mut);
          //   }
          // });
          // _this.contract_selected.contract.bank_mutations = array;
          _this.$emit("changed");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Betaalschema verwijderd',
              icon: 'BellIcon',
              text: 'Betaalschema is verwijderd',
              variant: 'danger',
            },
          })
      });
    },
    addSchemaMutation(schema) {
      let _this = this;
      let formData =  new FormData();
      
      let values = Object.values(schema);
      let keys = Object.keys(schema);
      console.log(keys,values);

      keys.forEach((key, index)=>{
        formData.append(key, values[index]);
      })
      formData.append("contract_id", this.contract.hashid);

      this.$http.post("/schemamutation", formData).then(function(response) {
        console.log("Schema mutation:", response.data.mutation);
        _this.contract.contract.schema_mutations.push(response.data.mutation);
        _this.$emit('changed', response.data.mutation);
      });
    }
  },
}
</script>

<style lang="scss" scoped>

</style>