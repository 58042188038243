<template>
  <div>
    <div class="text-center"><strong>Dit betreft een aflossingsvrije lening. De periode kan gewijzigd worden.</strong></div>
    <b-row class="mt-2 justify-content-center">
      <b-col cols="12" md="6">
        <label class="d-flex">
          Wanneer dient de wijziging in te gaan?
        </label>
        <validation-provider
          #default="{ errors }"
          name="datum"
          rules="required"
        >
          <cleave
            v-model="changeContract.startDate"
            placeholder="01-10-1987"
            :options="maskOptions.date"
            :raw="false"
            class="form-control"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="mt-1" v-if="false">
      <b-col class="text-center">
        <b-button-group>
          <b-button @click="buttonActive = 'duration'" :class="buttonActive=='duration' ? 'active' : ''">Looptijd</b-button>
          </b-button-group>
      </b-col>
    </b-row>

    <div v-if="buttonActive == 'duration'">
      <b-row class="mt-2">
        <b-col class="text-center">
          <div class="d-flex justify-content-center">
            <b-button @click="numberOfMonths-1 < 0 ? 0 : numberOfMonths -= contract.contract.payment_period" variant="primary" class="mr-1">-</b-button>
            <validation-provider
              #default="{ errors }"
              name="datum"
              rules="required|min_value:1|max_value:600"
            >
              <b-input-group append="maanden" style="width: 200px" >
                  <b-form-input type="number" min="1" v-model="numberOfMonths"></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-button @click="numberOfMonths += contract.contract.payment_period" variant="primary" class="ml-1">+</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 text-center">
        <b-col class="">
          Uw looptijd is vanaf {{changeContract.startDate}} hierdoor <span class="output">{{numberOfMonths/contract.contract.payment_period}}</span> perioden.
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-2 text-center">
        <b-col class="m-1">
          Uw eerst volgende rente betaling bedraagt <span class="output">€ {{interest_payment}}</span>.        
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col class="text-center m-1">
        <b-button variant="warning m-1" @click="addSchema()">Wijzig betaalschema</b-button>
        <b-button variant="primary m-1" @click="$emit('close')">Sluit</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// import store from '@/store'
/* eslint-disable vue/no-unused-components */
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    contract: {
      type: Object,
      default: null
    }
  },
  components: {
    ValidationProvider,
    Cleave,
    ToastificationContent
  },
  data() {
    return {
      changeContract: {
        startDate: this.contract.contract.next_payment_date.split("T")[0].split("-").reverse().join("-")
      },
      buttonActive: "duration",
      repayment_amount: 0,
      numberOfMonths: 0,
      maskOptions: {
        date: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-",
          dateMin: this.contract.start_date.split("T")[0]
        },
      }
    }
  },
  mounted() {
    this.numberOfMonths = this.contract.contract.length
  },
  computed: {
    interest_payment() {
      let period_index = this.getPeriodIndex(new Date(this.changeContract.startDate.split("-").reverse().join("-")));
      return (this.contract.contract.optimal[period_index].end_balance*this.fromPeriods(this.contract.contract.interests[0].interest, this.contract.contract.payment_period)).toFixed(2);
    }
  },
  methods: {
    getPeriodIndex(date) {
      let start_date = new Date(this.contract.start_date);
      let deltaYears = (date.getFullYear() - start_date.getFullYear())*12;
      let deltaMonths = date.getMonth() - start_date.getMonth();
      console.log({deltaYears});
      console.log({deltaMonths});

      let falls_within = (date.getDate() - start_date.getDate()) < 0 && (deltaYears + deltaMonths) % this.contract.contract.payment_period === 0 ? -1 : 0;
      return Math.floor((deltaMonths+deltaYears+falls_within)/this.contract.contract.payment_period);
    },
    fromPeriods(yearly, p) {
      return (Math.pow((1+yearly/100),(1/(12/p)))-1);
    },
    addSchema() {
      if(this.buttonActive === "duration") {
        this.$emit("added",{date:this.changeContract.startDate.split("-").reverse().join("-"), type: 1, value: this.numberOfMonths});
      }
      if(this.buttonActive === "repayment_amount") {
        this.$emit("added",{date:this.changeContract.startDate.split("-").reverse().join("-"), type: 2, value: this.repayment_amount});
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Gewijzigd',
          icon: 'BellIcon',
          text: 'Betaalschema is gewijzigd',
          variant: 'success',
        },
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.output {
  padding: 0.5em;
  margin: 0 2px;
  background-color: var(--primary);
  color: white;
  border-radius: 10px;
}


</style>
