<template>
  <div v-if="contract">
    <section class="">
      <b-container>
        <b-row class="d-flex justify-content-between">
          <b-col cols="12" lg="4" class="d-flex order-lg-2 mb-2 mb-lg-0 justify-content-center justify-content-lg-end" v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
            <b-row>
              <b-col cols="6" v-if="!contract.contract.signed_date">
                <b-button variant="primary" @click="markSigned()">Markeer als <br /> ondertekend</b-button>
              </b-col>
              <b-col cols="6" v-if="!contract.file && contract.contract.signed_date">
                <input type="file" ref="selectContract" accept="application/pdf" @change="onContractSelected" style="display: none">
                <b-button variant="info" @click="selectContract()">Upload contract</b-button>
              </b-col>
              <b-col cols="6" v-if="contract.file">
                <input type="file" ref="selectContract" accept="application/pdf" @change="onContractSelected" style="display: none">
                <b-dropdown variant="primary" text="Contract" class="d-flex align-items-center">
                  <b-dropdown-item @click="downloadSignedContract()">Download contract</b-dropdown-item>
                  <b-dropdown-item @click="selectContract()">Herupload contract</b-dropdown-item>
                </b-dropdown>
              </b-col>
              <!-- <b-col cols="6">
                <b-button variant="secondary" @click="$bvModal.show('delete-contract')">Verwijder <br /> overeenkomst</b-button>
              </b-col> -->
            </b-row>
          </b-col>
          <b-modal id="delete-contract" no-enforce-focus no-close-on-backdrop title="Verwijder contract">
            <p class="my-2">
              Je staat op het punt dit contract te verwijderen. Dit kan niet ongedaan gemaakt worden. Weet je zeker dat je door wilt gaan?
            </p>
            <template #modal-footer>
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button variant="" @click="$bvModal.hide('delete-contract')">
                Annuleer
              </b-button>
              <b-button variant="danger" @click="deleteContract(); $bvModal.hide('delete-contract')">
                Verwijder contract
              </b-button>
            </template>
          </b-modal>
          <b-col cols="12" lg="8" class="d-flex flex-column">
            <b-row class="d-flex justify-content-start">
              <b-col cols="6" sm="" class="mb-1 mb-md-0">
                <div class="title font-weight-bold">
                  Uitlener
                </div>
                <div class="value">
                  <b-avatar-group v-if="contract.senders">
                    <b-avatar v-b-tooltip.hover class="pull-up" :title="contract.senders[0].firstname + ' ' + contract.senders[0].lastname" :src="contract.senders[0].image" :text="contract.senders[0].image === null ? contract.senders[0].firstname[0] + contract.senders[0].lastname[0] : null"></b-avatar>
                    <b-avatar v-if="contract.senders.length > 1" v-b-tooltip.hover class="pull-up" :title="contract.senders[1].firstname + ' ' + contract.senders[1].lastname" :src="contract.senders[1].image" :text="contract.senders[1].image === null ? contract.senders[1].firstname[0] + contract.senders[1].lastname[0] : null"></b-avatar>
                  </b-avatar-group>
                </div>
              </b-col>

              <b-col cols="6" sm="">
                <div class="d-flex flex-column">
                  <div class="title font-weight-bold">
                    Ingegaan op
                  </div>
                  <div class="value">
                    {{ new Date(this.contract.start_date).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }) }}
                  </div>
                </div>
              </b-col>

              <b-col cols="6" sm="" class="">
                <div class="title font-weight-bold">
                  Status
                </div>
                <div class="value">
                  <b-badge v-if="this.contract.contract.signed_date !== null" pill variant="success">
                    Ondertekend
                  </b-badge>
                  <b-badge v-if="this.contract.contract.signed_date === null" pill variant="warning">
                    Niet ondertekend
                  </b-badge>
                </div>
              </b-col>

              <b-col cols="6" sm="" class="">
                <div class="title font-weight-bold">
                  Lener
                </div>
                <div class="value">
                  <b-avatar-group v-if="contract.receivers">
                    <b-avatar v-b-tooltip.hover class="pull-up" :src="contract.receivers[0].image" :title="contract.receivers[0].fullname" :text="contract.receivers[0].image === null ? contract.receivers[0].firstname[0] + contract.receivers[0].lastname[0] : null"></b-avatar>
                    <b-avatar v-if="contract.receivers.length > 1" v-b-tooltip.hover class="pull-up" :src="contract.receivers[1].image" :title="contract.receivers[1].fullname" :text="contract.receivers[1].image === null ? contract.receivers[1].fullname : null"></b-avatar>
                  </b-avatar-group>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="mt-3">
      <b-container>
        <b-row>
          <b-col md="4" class="d-flex flex-column">
            <h3>Contract details</h3>
            <b-card>
              <b-row class="d-flex justify-content-start">
                <b-col cols="12" sm="12" md="12">
                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="ClockIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold">{{ $t("contracts." + contract.type) }}</div>
                        <div class="key">Contract type</div>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="ClockIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold" v-if="contract.contract.optimal">{{ (contract.contract.optimal.length / (12 / contract.contract.payment_period)).toFixed(2) }} jaar</div>
                        <div class="key">Looptijd</div>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="PercentIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold">{{ contract.contract.interests[contract.contract.interests.length - 1].interest }} %</div>
                        <div class="key">Rente</div>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="CalendarIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold">{{ getPeriodName(contract.contract.payment_period) }}</div>
                        <div class="key">Betalingstermijn</div>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="BellIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold">{{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(contract.contract.sub_contract.expected_period_payment) }}</div>
                        <div class="key">
                          <span v-if="contract.type == 'AnnuityLoanContract'">Termijnbedrag</span>
                          <span v-if="contract.type == 'LinearLoanContract'">Aflossingsbedrag</span>
                          <span v-if="contract.type == 'RepaymentFreeLoanContract'">Rentebedrag</span>
                        </div>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="mb-1" v-if="contract.contract.periods_left > 0">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="ClockIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <span class="value font-weight-bold">{{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(contract.contract.next_period_payment) }}</span>
                        <span class="value font-weight-bold"> op {{ new Date(this.contract.contract.next_payment_date).toLocaleDateString('nl-NL', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span>
                        <div class="key">Volgende betaling</div>
                      </div>
                    </div>
                  </b-row>

                  <!-- <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="BellIcon"/>
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold" >{{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(contract.contract.next_period_payment)}}</div>
                        <div class="key">
                          <span>Volgende betaling</span>
                        </div>
                      </div>
                    </div>
                  </b-row> -->

                  <b-row class="mb-1">
                    <div class="d-flex">
                      <b-avatar class="mb-1 ml-1" :variant="`light-info`" size="35">
                        <feather-icon size="21" icon="CheckIcon" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="value font-weight-bold" v-if="contract.contract.optimal">{{ new Date(contract.contract.actual[contract.contract.actual.length - 1].period_date).add(contract.contract.payment_period).months().add(-1).days().toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }) }}</div>
                        <div class="key">Datum laatste betaling</div>
                      </div>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col v-if="lineAreaChart.data.datasets[0] && lineAreaChart.data.datasets[0].data.length > 1" md="8" class="d-flex flex-column">
            <h3>Rentegrafiek</h3>
            <b-card no-body style="height:100% !important;">

              <!-- chart -->
              <b-card-body>
                <line-chart-component :height="450" v-if="!redrawChart" id="chart" :data="lineAreaChart.data" :options="lineAreaChart.options" />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-else md="8" class="d-flex flex-column">
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="mt-4">
      <b-container>
        <b-row>
          <b-col>
            <h1>Betaalschema</h1>
            <b-card>
              <div v-if="$auth.hasRole(['admin', 'superadmin'])" class="d-flex justify-content-between">
                <div>
                  <b-button variant="primary" class="mb-1 mb-md-0 mr-sm-1" v-b-modal.mutation>
                    Mutatie registreren
                  </b-button>
                  <b-modal id="mutation" size="lg" no-enforce-focus no-close-on-backdrop hide-footer>
                    <template #modal-title>
                      <h2>Mutatie toevoegen</h2>
                    </template>
                    <MutationModal v-if="contract" :contract="contract" @added="mutationAdded" @deleted="mutationDeleted" />
                  </b-modal>
                  <b-button variant="primary" class="mb-1 mb-md-0" no-close-on-backdrop v-b-modal.repayment>
                    Betaalschema aanpassen
                  </b-button>
                  <b-modal id="repayment" no-enforce-focus body-class="p-3" no-close-on-backdrop centered hide-header hide-footer>
                    <ConfirmChangeRepaymentSchedule @yes="$bvModal.show('change-repayment'), $bvModal.hide('repayment')" @no="$bvModal.hide('repayment')" />
                  </b-modal>
                  <b-modal id="change-repayment" size="lg" no-close-on-backdrop no-enforce-focus hide-footer>
                    <template #modal-title>
                      <h2>Betaalschema aanpassen</h2>
                    </template>
                    <ChangeRepaymentSchedule v-if="contract" :contract="contract" @changed="changeAddded" @close="$bvModal.hide('change-repayment')" />
                  </b-modal>
                </div>
                <h1 v-if="contract.contract.actual && contract.contract.actual.length > 1">{{ m.format(contract.contract.actual[contract.contract.actual.length - 1].end_balance) }}</h1>
              </div>

              <b-card-body>
                <b-row>
                  <b-table class="mt-2" responsive sticky-header borderless :items="contract.contract.actual.filter(e => new Date(e.period_date).getTime() < new Date().add(future_periods * contract.contract.payment_period).months().getTime())" :fields="concept.fields" sort-by="date" :sort-desc="true">
                    <template #cell(percentage)="row">
                      <div style="width: 50px; height: 50px">
                        <vue-apex-charts type="radialBar" :options="chartOptions" :series="[(row.item.payment / (row.item.interest_unpaid + contract.contract.optimal[row.index].repayment_total + contract.contract.optimal[row.index].interest_total) * 100).toFixed(2)]"></vue-apex-charts>
                      </div>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <div class="btn-group">
                      <b-button variant="primary" class="btn btn-default" @click="addNextPeriod">Periode in de toekomst laten zien</b-button>
                      <b-button variant="primary" class="btn btn-default" @click="showAllPeriods">Alle perioden laten zien</b-button>
                      <b-button variant="primary" class="btn btn-default" @click="resetPeriods">Laat tot en met huidige periode zien</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import store from '@/store'
// import flatPickr from 'vue-flatpickr-component'
import LineChartComponent from './LineChartComponent.vue'
import { $themeColors } from '@themeConfig'

import MutationModal from '@/components/modals/MutationModal.vue'
import ConfirmChangeRepaymentSchedule from '@/views/loan/modals/ConfirmChangeRepaymentSchedule.vue'
import ChangeRepaymentSchedule from '@/views/loan/modals/change-repayment-schedule/ChangeRepaymentSchedule.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import VueApexCharts from "vue-apexcharts"

import { mapActions } from "vuex"

require("datejs")

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: 'rgba(100,100,100)',
  blueColor: 'rgba(1, 161, 137)',
  blueLightColor: 'rgba(1, 161, 137)',
  greyLightColor: 'rgba(100, 100, 100)',
  blueLightColor2: 'rgba(1, 161, 137)',
  orangeLightColor: 'rgba(255,127,8)',
  orangeLightColor2: 'rgba(231, 111, 8)',
  greyLightColor2: 'rgba(100, 100, 100)',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200)',
  optimalDark: 'rgba(33, 50, 102, 1)',
  optimalMiddle: 'rgba(66,98,194,1)',
  optimalLigth: 'rgba(116, 140, 210, 1)',
  actualDark: 'rgba(45,45,45,1)',
  actualLight: 'rgba(120,120,120, 1)',
}

export default {
  components: {
    LineChartComponent,
    VueApexCharts,
    MutationModal,
    ConfirmChangeRepaymentSchedule,
    ChangeRepaymentSchedule,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    //populate
    let _this = this;
    this.$http.get("/contract", { params: { hashid: this.$route.params.id } }).then((response) => {
      _this.contract = response.data.contract
      console.log(_this.contract);
      _this.updateChart();
    });
  },
  data() {
    let m = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
    let d = new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'numeric', day: 'numeric' });
    return {
      m,
      d,
      actual: [],
      optimal: [],
      redrawChart: false,
      contracts: [],
      contract: null,
      future_periods: 0,
      concept: {
        fields: [
          { key: 'period_date', label: 'Datum periode', sortable: true, formatter: (value) => { return d.format(new Date(value)) } },
          { key: 'start_balance', label: 'Uitstaand', formatter: (value) => { return m.format(value); }, },
          { key: 'repayment_total', label: 'Aflossing', formatter: (value) => { return m.format(value); }, },
          { key: 'interest_total', label: 'Rente betaald', formatter: (value) => { return m.format(value); }, },
          { key: 'interest_unpaid', label: 'Rente openstaand', formatter: (value) => { return m.format(value); }, },
          { key: 'payment', label: 'Betaling', formatter: (value) => { return m.format(value); }, },
          { key: 'end_balance', label: 'saldo', formatter: (value) => { return m.format(value); }, }
          // { key: 'percentage', label: '', tdClass: 'text-right'},
        ],
        items: [

        ],
      },
      chartOptions: {
        chart: {
          type: 'radialBar',
        },
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -15,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "8px",
                offsetY: 3,
              },
            },
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          }
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Percentage'],
      },
      rangePicker: ['2019-05-01', '2019-05-10'],
      lineAreaChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function (tooltipItem, chart) {
                let datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                return datasetLabel + ': ' + m.format(tooltipItem.yLabel);
              }
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  fontColor: chartColors.labelColor,
                  callback: function (value) {
                    return "€" + value;
                  }
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [

          ],
          datasets: [
            {
              label: 'Aflossing (beoogd)',
              data: [],
              lineTension: 0,
              backgroundColor: chartColors.optimalDark,
              fill: false,
              pointStyle: 'circle',
              borderColor: chartColors.optimalDark,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: chartColors.optimalDark,
              pointHoverBackgroundColor: chartColors.optimalDark,
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Rente (beoogd)',
              data: [],
              lineTension: 0,
              backgroundColor: chartColors.optimalMiddle,
              fill: false,
              pointStyle: 'circle',
              borderColor: chartColors.optimalMiddle,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: chartColors.optimalMiddle,
              pointHoverBackgroundColor: chartColors.optimalMiddle,
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Aflossing - huidig',
              data: [],
              lineTension: 0,
              backgroundColor: chartColors.actualDark,
              fill: false,
              pointStyle: 'circle',
              borderColor: chartColors.actualDark,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: chartColors.actualDark,
              pointHoverBackgroundColor: chartColors.actualDark,
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Rente - huidig',
              data: [],
              lineTension: 0,
              backgroundColor: chartColors.actualLight,
              fill: false,
              pointStyle: 'circle',
              borderColor: chartColors.actualLight,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: chartColors.actualLight,
              pointHoverBackgroundColor: chartColors.actualLight,
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Rente + Aflossing (beoogd)',
              data: [],
              lineTension: 0,
              backgroundColor: chartColors.optimalLigth,
              fill: false,
              pointStyle: 'circle',
              borderColor: chartColors.optimalLigth,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: chartColors.optimalLigth,
              pointHoverBackgroundColor: chartColors.optimalLigth,
              pointHoverBorderColor: $themeColors.light,
            }
          ],
        },
      },
    }
  },
  methods: {
    ...mapActions({ removeContract: "contracts/remove" }),
    resetPeriods() {
      this.future_periods = 0;
      this.updateChartActual();
      console.log(this.future_periods);
      let _this = this;
      _this.redrawChart = true;
      setTimeout(() => { _this.redrawChart = false; }, 1);
    },
    addNextPeriod() {
      this.future_periods++;
      this.updateChartActual();
      console.log(this.future_periods);
      let _this = this;
      _this.redrawChart = true;
      setTimeout(() => { _this.redrawChart = false; }, 1);
    },
    showAllPeriods() {
      this.future_periods = 10000;
      this.updateChartActual();
      let _this = this;
      _this.redrawChart = true;
      setTimeout(() => { _this.redrawChart = false; }, 1);
    },
    downloadSignedContract() {
      let formData = new FormData()

      formData.append("hashid", this.contract.hashid)

      this.$http.post("/contract/download", formData, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/octet-stream'
        }
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'contract.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    onContractSelected(e) {
      let file = e.target.files[0]
      this.contract.file = file

      let formData = new FormData()

      formData.append("hashid", this.contract.hashid)
      formData.append("file", this.contract.file)
      formData.append("data", this.contract.data)
      formData.append("_method", "PATCH")

      this.$http.post("/contract/upload", formData).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Geupdate',
            icon: 'BellIcon',
            text: 'Contract is geupload',
            variant: 'success',
          },
        })
      })
    },
    selectContract() {
      this.$refs.selectContract.click()
    },
    getCalculation() {
      let _this = this;
      _this.$http.get("/contract/calculate", {
        params: {
          id: _this.contract.hashid
        }
      }).then((response) => {
        if (response.data.recalculated) {
          _this.contract.contract.optimal = response.data.optimal;
          _this.contract.contract.actual = response.data.actual;

          _this.contract.contract.next_period_payment = response.data.next_period_payment;

          _this.updateChart();
          _this.redrawChart = true;
          setTimeout(() => { _this.redrawChart = false; }, 1);
        }
      });
    },
    beforeDestroy() {
      this.lineAreaChart.data.datasets[0].data = [];
      this.lineAreaChart.data.datasets[1].data = [];
      this.lineAreaChart.data.datasets[4].data = [];
    },
    getPeriodName(val) {
      val = parseInt(val)
      switch (val) {
        case 1: {
          return "Maandelijks"
        }
        case 3: {
          return "Kwaartalijks"
        }
        case 6: {
          return "Halfjaarlijks"
        }
        case 12: {
          return "Jaarlijks"
        }
      }
    },
    changeAddded() {
      this.getCalculation();
    },
    mutationAdded() {
      this.getCalculation();
    },
    mutationDeleted() {
      this.getCalculation();
    },
    updateChart() {
      this.lineAreaChart.data.labels = this.contract.contract.optimal.map(e => (new Date(e.period_date)).toLocaleDateString("nl-NL"));
      this.rangePicker[0] = (new Date(this.contract.contract.optimal[0].period_date)).toLocaleDateString("nl-NL");
      this.rangePicker[1] = (new Date(this.contract.contract.optimal[this.contract.contract.optimal.length - 1].period_date)).toLocaleDateString("nl-NL");

      this.lineAreaChart.data.datasets[0].data = this.contract.contract.optimal.map(e => e.repayment_total);
      this.lineAreaChart.data.datasets[1].data = this.contract.contract.optimal.map(e => e.interest_total);
      this.lineAreaChart.data.datasets[4].data = this.contract.contract.optimal.map(e => e.repayment_total + e.interest_total);

      this.updateChartActual();
    },
    updateChartActual() {
      let _this = this;
      let repayment_current = this.contract.contract.actual.filter(e => new Date(e.period_date).getTime() < new Date().add(_this.future_periods * _this.contract.contract.payment_period).months().getTime()).map(e => {
        return e.repayment_total
      });
      let interest_current = this.contract.contract.actual.filter(e => new Date(e.period_date).getTime() < new Date().add(_this.future_periods * _this.contract.contract.payment_period).months().getTime()).map(e => {
        return e.interest_unpaid
      });

      console.log(repayment_current.length);
      console.log(interest_current.length);

      this.lineAreaChart.data.datasets[2].data = repayment_current;
      this.lineAreaChart.data.datasets[3].data = interest_current;
    },
    markSigned() {
      this.$http.post("/sign", { hashid: this.contract.hashid }).then((response) => {
        this.$swal('Contract ondertekend!',
          'Je hebt het contract gemarkeerd als ondertekend!',
          'success');
        this.contract.contract.signed_date = response.data.contract.contract.signed_date;
      });
    },
    deleteContract() {
      let _this = this;
      this.removeContract(this.contract.hashid).then(() => {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contract verwijderd',
            icon: 'BellIcon',
            text: 'Je contract is verwijderd.',
            variant: 'danger',
          },
        })
        _this.$router.push({ name: 'overview' })
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
