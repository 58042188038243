<template>
  <b-container class="text-center">
    <strong>Heb je alle betalingen ingevoerd?</strong>
    <div class="mt-1">Om een nieuwe en accuraat betaalschema te berekenen, moeten je inboekingen goed geregistreerd zijn, anders zal het nieuwe schema niet kloppen</div>
    <b-button-group size="lg" class="mt-1">
      <b-button variant="primary" @click="$emit('yes')">Ja</b-button>
      <b-button variant="primary" @click="$emit('no')">Nee</b-button>
    </b-button-group>
  </b-container>
</template>

<script>
// import store from '@/store'

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>


</style>

